import React, { useState, useEffect, useRef } from "react"
import PMPDealForm from "./pmp-deal-form"

const HeroSection = ({ brandsLogos, title, slug }) => {
  const [paddingTop, setPaddingTop] = useState(70)
  const [shouldShrinkTitle, setShouldShrinkTitle] = useState(false)
  const titleRef = useRef(null)

  useEffect(() => {
    console.log("path", slug)
    const el = titleRef.current
    if (!el) return

    const updateLayout = () => {
      setPaddingTop(window.innerWidth < 768 ? 10 : 80)

      const style = window.getComputedStyle(el)
      const lineHeight = parseFloat(style.lineHeight || "0")
      const height = el.clientHeight

      if (lineHeight > 0) {
        const lines = height / lineHeight

        setShouldShrinkTitle(lines >= 2.5)
      }
    }

    updateLayout()
    window.addEventListener("resize", updateLayout)

    return () => window.removeEventListener("resize", updateLayout)
  }, [title, slug])

  return (
    <section
      className="hero landing-hero pmp-container"
      style={{ position: "relative" }}
    >
      <div className="gradient-bg has-background-dark">
        {slug !== "ai-pmp" && (
          <div class="gradients-container">
            <div class="gradient g1"></div>
            <div class="gradient g4"></div>
            <div class="gradient g5"></div>
          </div>
        )}
      </div>

      <div
        className="hero-body"
        style={{ paddingTop: `${paddingTop}px`, minHeight: "100vh" }}
      >
        <div className="container" style={{ width: "100%" }}>
          <div className="columns is-align-items-center is-justify-content-space-between">
            <div className="column title-column">
              <div className="title-height">
                <h1
                  ref={titleRef}
                  className={`title pmp-title has-text-white has-text-weight-medium ${
                    shouldShrinkTitle ? "shrink-title" : ""
                  }`}
                >
                  {title}
                </h1>
              </div>
              <h2
                className={`is-size-4-desktop is-size-6-mobile has-text-white has-text-weight-light pt-2 ${
                  shouldShrinkTitle ? "shrink-subtitle" : ""
                }`}
              >
                Easily activate AI optimization algorithms for any programmatic
                campaign with inPowered AI’s PMPs and boost KPIs 50%-300%.
              </h2>

              <div className="is-flex logos-container is-justify-content-space-between is-align-items-center is-hidden-mobile pt-4">
                {brandsLogos.map(el => (
                  <img key={el.name} src={el.logo} alt={el.name} />
                ))}
              </div>
            </div>

            <div className="column form-column is-6">
              <PMPDealForm formPlacement="Landing Page PMP - Top" />

              <div className="is-flex logos-container is-justify-content-space-between is-align-items-center pt-6 py-5 is-hidden-desktop">
                {brandsLogos.map(el => (
                  <img key={el.name} src={el.logo} alt={el.name} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
